<template>
  <div class="card-carousel">
    <carousel
      :perPageCustom="[[0, 1], [768, 2], [1024, 3], [1700, 3]]"
      :scrollPerPage="false"
      :mouse-drag="false"
      :navigation-enabled="true"
      :pagination-enabled="false"
      class="carousel-arrow-navigation"
    >
      <slide
        v-for="(slide, n) in carouselItems"
        :key="n"
        class="card-carousel__item"
      >
        <div
          :class="[ cardDisplay == 'Stacked card' ? 'card-carousel-item-stacked-card' : 'card-carousel-item-full-card']"
          class="card-carousel-item"
        >
          <div
            @click="onCardClick(slide)"
            class="card-carousel-item__inner"
            :class="{ 'card-carousel-item__inner--no-image': !isSomeItemHasImage, 'card-carousel-item__inner--overlay': slide.title, 'card-link': slide.link }"
          >
            <img
              v-if="slide.image"
              :src="getImage(slide)"
              :alt="slide.imageAlt || slide.title"
              :title="slide.imageDescription || slide.title"
              loading="lazy"
              class="card-carousel-item__img"
            />
            <span class="card-carousel-item__title" v-html="slide.title"></span>
          </div>
          <div
            v-if="cardDisplay == 'Full card'"
            class="full-card-text"
          >
            <h3 v-if="slide.title" class="card-title" v-html="slide.title"></h3>
            <p class="card-description" v-html="slide.description"></p>
            <a v-if="slide.link" @click="onCardClick(slide)" class="button button--underlined">Read More</a>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'

export default {
  name: 'CardCarousel',
  components: {
    Carousel,
    Slide
  },
  mixins: [ScreenWidthMixin],
  props: {
    carouselItems: Array,
    cardDisplay: {
      type: String,
      default: 'Stacked card'
    }
  },
  computed: {
    isSomeItemHasImage () {
      return this.carouselItems.some(el => el.image)
    }
  },
  methods: {
    onCardClick (item) {
      if (item.link) {
        this.$router.push(item.link.url)
      }
    },
    getImage (entry) {
      return this.isMobile && entry.imageMobile ? entry.imageMobile : entry.image
    }
  }
}
</script>
