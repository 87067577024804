<template>
  <div
    class="card-grid"
    :class="`card-grid-${columns}`"
  >
    <div
      v-for="card in cards"
      :key="card.title"
      class="card-grid-item"
      :class="[ cardDisplay == 'Stacked card' ? 'card-grid-item-stacked-card' : 'card-grid-item-full-card']"
    >
      <div
        @click="onCardClick(card)"
        class="card-grid-item__inner"
        :class="{'card-grid-item__inner--overlay': card.title, 'card-link': card.link}"
      >
        <img
          v-if="card.image"
          :src="getImage(card)"
          :alt="card.imageAlt || card.title"
          :title="card.imageDescription || card.title"
          loading="lazy"
          class="card-grid-item__img"
        />
        <span class="card-grid-item__title" v-html="card.title"></span>
      </div>
      <div
        v-if="cardDisplay == 'Full card'"
        class="full-card-text"
      >
        <h3 v-if="card.title" class="card-title" v-html="card.title"></h3>
        <p class="card-description" v-html="card.description"></p>
        <a v-if="card.link" @click="onCardClick(card)" class="button button--underlined">Read More</a>
      </div>
    </div>
  </div>
</template>

<script>

import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'

export default {
  name: 'CardGrid',
  mixins: [ScreenWidthMixin],
  props: {
    cardDisplay: {
      type: String,
      default: 'Stacked card'
    },
    columns: {
      type: Number,
      default: 2
    },
    cards: Array
  },
  methods: {
    onCardClick (item) {
      if (item.link) {
        if (/((http(s?)):\/\/)/.test(item.link.url) === true) {
          window.open(item.link.url, '_blank')
        } else {
          this.$router.push(item.link.url)
        }
      }
    },
    getImage (entry) {
      return this.isMobile && entry.imageMobile ? entry.imageMobile : entry.image
    }
  }
}
</script>
