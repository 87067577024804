<template>
  <div class="categories-carousel">
    <carousel
      :perPageCustom="[[0, 1], [768, 2], [1024, 3], [1700, 3]]"
      :scrollPerPage="false"
      :mouse-drag="false"
      :navigation-enabled="true"
      :pagination-enabled="false"
      class="carousel-arrow-navigation"
    >
      <slide
        v-for="(slide, n) in carouselItems"
        :key="n"
        class="categories-carousel__item"
      >
        <div class="category-carousel-item">
          <div
            @click="onCategoryClick(slide)"
            class="category-carousel-item__inner"
            :class="{ 'category-carousel-item__inner--no-image': !isSomeItemHasImage, 'category-carousel-item__inner--overlay': slide.image }"
          >
            <img
              v-if="slide.image"
              :src="getImage(slide)"
              :alt="slide.imageAlt || slide.title"
              :title="slide.imageDescription || slide.title"
              loading="lazy"
              class="category-carousel-item__img"
            />
            <span class="category-carousel-item__title" v-html="slide.title"></span>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'

export default {
  name: 'CategoriesCarousel',
  components: {
    Carousel,
    Slide
  },
  mixins: [ScreenWidthMixin],
  props: {
    carouselItems: Array
  },
  computed: {
    isSomeItemHasImage () {
      return this.carouselItems.some(el => el.image)
    }
  },
  methods: {
    onCategoryClick (item) {
      this.$router.push(item.url)
    },
    getImage (entry) {
      return this.isMobile && entry.imageMobile ? entry.imageMobile : entry.image
    }
  }
}
</script>
