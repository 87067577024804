<template>
  <div v-if="carouselData" class="carousel carousel-pagination-flat carousel-arrow-navigation" ref="carousel">
    <carousel
      :per-page="currentBannersPerPage"
      :mouse-drag="false"
      :autoplay="carouselData.autoplayTimeout ? true : false"
      :autoplayHoverPause="false"
      :autoplayTimeout="carouselData.autoplayTimeout"
      :loop="true"
      :navigation-enabled="isMobile ? false : true"
      @pagination-click="handlePaginationClick"
    >
      <slide
        v-for="(slide, n) in this.slides"
        :key="n"
      >
        <HeroBanner
          :isDecorated="true"
          :data="slide"
          :parentKey="parentKey"
          :enableInnerImpressionPromotionAnalytics="false"
          ref="heroBanner"
        />
      </slide>
    </carousel>
  </div>
</template>

<script>
import _ from 'lodash'
import { Carousel, Slide } from 'vue-carousel'

import { checkIsElementInView } from '@/helpers'
import NavigationalMixin from '@/mixins/NavigationalMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import HeroBanner from '@/components/HeroBanner'

export default {
  name: 'BannerCarousel',
  components: {
    Carousel,
    Slide,
    HeroBanner
  },
  mixins: [NavigationalMixin, ScreenWidthMixin],
  props: {
    carouselData: {
      type: Object,
      requried: true
    },
    parentKey: {
      type: String,
      required: true,
      default: ''
    }
  },
  watch: {
    isVisible (val) {
      if (this.isPromotionAnalyticsRequired) {
        this.handleInitialPromotionsLoad(val)
        window.removeEventListener('scroll', this.scrollListener)
      }
    }
  },
  computed: {
    slides () {
      return this.carouselData.items
    },
    isPromotionAnalyticsRequired () {
      return this.slides.some(el => el.isPromotionComponent)
    }
  },
  methods: {
    scrollListener: _.throttle(function () {
      this.isVisible = checkIsElementInView(this.$refs.carousel, 25)
    }, 300),
    handleInitialPromotionsLoad () {
      if (this.isVisible && !this.viewedBanners.length) {
        const visibleOnLoadItemsIdxs = this.slides.slice(0, this.currentBannersPerPage).map((el, idx) => idx)
        this.viewedBanners.push(...visibleOnLoadItemsIdxs)
        this.viewedBanners.forEach(el => {
          if (this.$refs.heroBanner[el]) this.$refs.heroBanner[el].sendPromotionImpressionAnalytics()
        })
      }
    },
    handlePaginationClick (page) {
      if (this.isPromotionAnalyticsRequired && page !== 0) {
        const firstItemOfPageIndex = page * this.currentBannersPerPage
        let positionOfNextBannerToAdd = firstItemOfPageIndex
        const bannersToAdd = this.slides
          .slice(firstItemOfPageIndex, firstItemOfPageIndex + this.currentBannersPerPage)
          .map(() => {
            ++positionOfNextBannerToAdd
            return positionOfNextBannerToAdd - 1
          })
        if (!bannersToAdd.every(el => this.viewedBanners.includes(el))) {
          this.viewedBanners.push(...bannersToAdd)
          bannersToAdd.forEach(el => {
            if (this.$refs.heroBanner[el]) this.$refs.heroBanner[el].sendPromotionImpressionAnalytics()
          })
        }
      }
    }
  },
  mounted () {
    if (this.isPromotionAnalyticsRequired) {
      this.$nextTick(function () {
        this.isVisible = checkIsElementInView(this.$refs.carousel, 25)
        this.handleInitialPromotionsLoad()
        window.addEventListener('scroll', this.scrollListener)
      })
    }
  },
  beforeDestroy () {
    if (this.isPromotionAnalyticsRequired) window.removeEventListener('scroll', this.scrollListener)
  },
  data () {
    return {
      isVisible: false,
      currentBannersPerPage: 1,
      viewedBanners: []
    }
  }
}
</script>
