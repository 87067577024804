<template>
  <div class="generic-content" v-if="genericContentPageData">
    <template v-if="genericContentPageData.heroBanner">
      <BannerCarousel
        v-if="genericContentPageData.heroBanner.carousel"
        :carouselData="genericContentPageData.heroBanner.carousel"
        :parentKey="genericContentPageData.heroBanner.parentKey"
      />
      <HeroBanner
        v-else
        :isDecorated="true"
        :data="genericContentPageData.heroBanner"
        :parentKey="genericContentPageData.key"
      />
    </template>
    <div
      v-for="item in genericContentPageData.slots"
      :key="item.key"
      class="generic-content__section"
      :class="[
        item.container === 'Container' ? 'container' : '',
        `generic-content__section--alignment-${item.alignment}`,
        item.verticalPadding ? '' : 'generic-content__section--no-padding'
      ]"
    >
      <Banner
        v-if="item.slot.type === 'blockBannerImage' || item.slot.type === 'blockBannerContent'"
        :data="item.slot"
        :parentKey="genericContentPageData.key"
      />
      <a
        v-else-if="item.slot.type === 'particleLink'"
        v-html="item.slot.title"
        :href="item.slot.url"
        class="button button--primary"
      ></a>
      <TextSection
        v-if="item.slot.type === 'particleText'"
        :header="item.slot.header"
        :alignment="item.alignment"
        :text="item.slot.paragraph"
      />
      <TwoColumnContent
        v-if="item.slot.type === 'blockTwoColumnContent'"
        :data="item.slot"
      />

      <SectionComponent
        v-if="item.slot.type === 'blockCarousel'"
        :title="item.slot.title"
        :link="item.slot.link"
        class="page-section product-carousel-section"
      >
        <div class="container" v-if="item.slot.carouselType === 'categories'">
          <CategoriesCarousel :carouselItems="item.slot.items" />
        </div>

        <ProductCarousel
          v-if="item.slot.carouselType === 'products'"
          :class="{'container': isMobile}"
          :productsList="item.slot.items"
          :isPriceVisible="true"
          :analyticsData="{referencePage: pageTitle, enableImpressions: true}"
        />

        <div class="container" v-if="item.slot.carouselType === 'cards'">
          <CardCarousel :carouselItems="item.slot.items" :cardDisplay="item.slot.cardDisplay" />
        </div>
      </SectionComponent>

      <div class="container" v-if="item.slot.type === 'blockCardGrid'">
        <h2 class="card-grid-title" v-html="item.slot.title" />
        <CardGrid
          :cardDisplay="item.slot.cardDisplay"
          :columns="item.slot.columns"
          :cards="item.slot.items"
        />
      </div>

      <VueScriptComponent
        v-if="item.slot.type === 'blockScript'"
        :script="item.slot.script"
      />
    </div>
  </div>
  <loader v-else />
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import PageMixin from '@/mixins/PageMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import HeroBanner from '@/components/HeroBanner'
import BannerCarousel from '@/components/BannerCarousel'
import Banner from '@/components/Banner'
import TextSection from '@/components/TextSection'
import TwoColumnContent from '@/components/TwoColumnContent'
import ProductCarousel from '@/components/ProductCarousel'
import SectionComponent from '@/components/SectionComponent'
import CategoriesCarousel from '@/components/catalog-sections/CategoriesCarousel'
import CardCarousel from '@/components/CardCarousel'
import CardGrid from '@/components/CardGrid'

export default {
  name: 'GenericContent',
  components: {
    HeroBanner,
    BannerCarousel,
    Banner,
    TextSection,
    TwoColumnContent,
    ProductCarousel,
    SectionComponent,
    CategoriesCarousel,
    CardCarousel,
    CardGrid
  },
  mixins: [PageMixin, ScreenWidthMixin],
  computed: {
    ...mapState('genericContent', ['genericContentPageData']),
    pageTitle () {
      return this.genericContentPageData?.meta?.title || this.genericContentPageData?.title
    },
    metaData () {
      return this.genericContentPageData?.meta
    }
  },
  methods: {
    ...mapActions('genericContent', ['setGenericContentPageData']),
    ...mapMutations('genericContent', { unsetGenericContentPageData: 'UNSET_GENERIC_CONTENT_PAGE_DATA' })
  },
  async created () {
    this.unsetGenericContentPageData()
    const pageKeySlug = this.$route.path
    await this.setGenericContentPageData(pageKeySlug)
    if (!this.genericContentPageData) this.$router.push('/404')

    this.$nextTick(() => {
      this.setPageTitle(this.pageTitle)
      this.setMetaTags()
    })
  },
  beforeDestroy () {
    this.unsetMetaTags()
  }
}
</script>
