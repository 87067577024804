<template>
  <section class="two-column-content">
    <div
      class="two-column-content__col two-column-content__col--left"
    >
      <div class="two-column-content__content">
        <img
          v-if="data.alignText === 'right'"
          :src="backgroundImage"
          loading="lazy"
          width="600"
          height="600"
          class="two-column-content__img"
        />
        <template v-else>
          <div
            class="two-column-content__main-text paragraph"
            v-if="data.paragraph"
            v-html="data.paragraph"
          ></div>
          <a
            v-if="data.link"
            v-html="data.link.title"
            :href="data.link.url"
            @click.prevent="navigateTo(data.url)"
            class="button button--primary"
          ></a>
        </template>
      </div>
    </div>
    <div
      class="two-column-content__col two-column-content__col--right"
    >
      <div class="two-column-content__content">
        <img
          v-if="data.alignText === 'left'"
          :src="backgroundImage"
          loading="lazy"
          width="600"
          height="600"
          class="two-column-content__img"
        />
        <template v-else>
          <div
            class="two-column-content__main-text paragraph"
            v-if="data.paragraph"
            v-html="data.paragraph"
          ></div>
          <a
            v-if="data.link"
            v-html="data.link.title"
            :href="data.link.url"
            @click.prevent="navigateTo(data.url)"
            class="button button--primary"
          ></a>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import NavigationalMixin from '@/mixins/NavigationalMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'

export default {
  name: 'TwoColumnContent',
  mixins: [NavigationalMixin, ScreenWidthMixin],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    backgroundImage () {
      return this.isMobile && this.data.imageMobile ? this.data.imageMobile : this.data.image
    }
  }
}
</script>
