<template>
  <section class="section">
    <div class="container" v-if="title || link">
      <div
        class="section-header"
        :class="[!title ? 'section-header--no-title' : '']"
      >
        <h2
          class="section-header__title color-title"
          :class="[isTitleDecorated ? 'color-title--decotated' : '', titleColor ? `color-title--${titleColor}` : '']"
          v-if="title"
          v-html="title"
        ></h2>
        <router-link
          class="section-header__link"
          v-if="link"
          v-html="link.title"
          :to="link.url"
        ></router-link>
      </div>
    </div>

    <slot></slot>

  </section>
</template>

<script>
export default {
  name: 'SectionComponent',
  props: {
    title: String,
    link: Object,
    titleColor: {
      type: String,
      default: 'yellow'
    },
    isTitleDecorated: {
      type: Boolean,
      default: true
    }
  }
}
</script>
