<template>
  <div class="banner" ref="banner">
    <a
      v-if="data && data.type === 'blockBannerImage'"
      @click.prevent="handleClick(data.link)"
      :href="data.link && data.link.url ? data.link.url : '/'"
      :class="[data.link ? 'image-banner--clickable' : '']"
      class="image-banner"
    >
      <img
        :src="backgroundImage"
        loading="lazy"
        :alt="alt"
        :title="data.imageDescription"
        width="1300"
        height="240"
        class="image-banner__img"
      />
    </a>

    <div
      v-if="data && data.type === 'blockBannerContent'"
      :style="`background-image: url('${backgroundImage}')`"
      class="content-banner"
      :class="[`content-banner--text-color-${data.textColor}`]"
    >
      <div class="content-banner__content">
        <div
          class="container content-banner__content-wrap"
          :class="[`content-banner__content-wrap--${data.alignText}`]"
        >
          <div
            class="content-banner__inner"
          >
            <p
              v-if="data.paragraph"
              v-html="data.paragraph"
              class="content-banner__main-text paragraph"
            ></p>
            <a
              v-if="data.link"
              @click.prevent="handleClick(data.link)"
              v-html="data.link.title"
              :href="data.link && data.link.url ? data.link.url : '/'"
              :class="buttonClasses"
              class="button content-banner__button"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'

import { checkIsElementInView } from '@/helpers'
import NavigationalMixin from '@/mixins/NavigationalMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'

export default {
  name: 'Banner',
  mixins: [NavigationalMixin, ScreenWidthMixin],
  props: {
    data: {
      type: Object,
      required: true
    },
    buttonStyle: {
      type: String,
      default: 'outline'
    },
    emitClickMode: {
      type: Boolean,
      default: false
    },
    parentKey: {
      type: String,
      required: true,
      default: ''
    }
  },
  computed: {
    backgroundImage () {
      return this.isMobile && this.data.imageMobile ? this.data.imageMobile : this.data.image
    },
    buttonClasses () {
      return [
        this.buttonStyle === 'primary' && this.data.textColor === 'white'
          ? 'button--primary-white'
          : this.buttonStyle === 'primary' && this.data.textColor === 'black'
            ? 'button--primary-black'
            : this.buttonStyle === 'outline' && this.data.textColor === 'white'
              ? 'button--outlined-white'
              : this.buttonStyle === 'outline' && this.data.textColor === 'black'
                ? 'button--outlined'
                : 'button--outlined'
      ]
    },
    alt () {
      return this.data?.imageAlt || this.data?.link?.title || this.data?.key
    },
    promotionAnalyticsData () {
      return {
        name: this.data.key,
        position: this.parentKey,
        creative: this.data.image
      }
    }
  },
  methods: {
    ...mapActions('analytics', ['populatePromotionClickAnalytics', 'populatePromotionImpressionAnalytics']),
    handleClick (link) {
      if (this.data.isPromotionComponent) this.populatePromotionClickAnalytics(this.promotionAnalyticsData)
      this.emitClickMode ? this.$emit('clicked') : this.navigateTo(link.url)
    },
    scrollListener: _.throttle(function () {
      const isBannerInView = checkIsElementInView(this.$refs.banner, 50)
      if (isBannerInView) {
        this.populatePromotionImpressionAnalytics(this.promotionAnalyticsData)
        window.removeEventListener('scroll', this.scrollListener)
      }
    }, 500)
  },
  mounted () {
    if (this.data.isPromotionComponent) {
      this.$nextTick(function () {
        const isBannerInView = checkIsElementInView(this.$refs.banner, 50)
        if (isBannerInView) {
          this.populatePromotionImpressionAnalytics(this.promotionAnalyticsData)
        } else {
          window.addEventListener('scroll', this.scrollListener)
        }
      })
    }
  },
  beforeDestroy () {
    if (this.data.isPromotionComponent) window.removeEventListener('scroll', this.scrollListener)
  }
}
</script>
